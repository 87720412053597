
.boxMaterialsPackage {
    /* background-color: #f7f7f7; */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" aria-hidden="true" clip-rule="evenodd" viewBox="-40 0 70 70" fill="%23e7e7e7"><path d="M34.87 65.09c-16.26 0-29.5-13.35-29.5-29.75S18.62 5.59 34.88 5.59a29.2 29.2 0 0 1 20.78 8.65l3.68-3.72a35.38 35.38 0 0 0-11-7.44A34.26 34.26 0 0 0 34.88.34C15.71.34.17 16 .17 35.34c0 19.33 15.54 35 34.7 35 9.55 0 18.19-3.89 24.46-10.18l-3.69-3.72a29.26 29.26 0 0 1-20.77 8.65z"/><path d="M8.85 35.34c0 14.5 11.66 26.25 26.02 26.25 7.16 0 13.62-2.92 18.33-7.62l-3.69-3.72a20.66 20.66 0 0 1-14.64 6.09 20.93 20.93 0 0 1-20.81-21c0-11.58 9.34-21 20.81-21 2.84 0 5.56.58 8.03 1.63l2.04-4.83A25.79 25.79 0 0 0 34.87 9.1C20.5 9.1 8.85 20.85 8.85 35.34z"/><path d="m48.63 13.06-2.78 4.45c1.33.83 2.55 1.8 3.66 2.9l3.7-3.71a26.5 26.5 0 0 0-4.58-3.64zM34.87 23.08a11.94 11.94 0 0 1 8.52 3.52l3.68-3.7a17.23 17.23 0 0 0-12.2-5.07 17.42 17.42 0 0 0-17.34 17.5c0 9.66 7.76 17.5 17.34 17.5 4.76 0 9.06-1.93 12.2-5.05l-3.68-3.72a12.05 12.05 0 0 1-8.5 3.52c-6.7 0-12.16-5.49-12.16-12.25a12.2 12.2 0 0 1 12.14-12.25z"/></svg>');
    background-size: 100%;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    transition: all .5s ease;
    position: relative;
  }
  